import { useTrialDescription } from '@/src/hooks';
import { PaymentType } from '../../Checkout/Checkout.enums';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import { PaymentOptionContext } from './PaymentOption';
import { useOfferStore } from '@/src/common/zustand';
import useCurrencyCodeStore from '@/src/common/zustand/useCurrencyCodeStore';
import { currencyText } from '@/src/common/components/OrderSummary/utils';

const Description = (): JSX.Element => {
  const { t } = useTranslation();
  const { option } = useContext(PaymentOptionContext);
  const currency = useOfferStore((state) => state.offer?.currency.symbol);
  const trialDescription = useTrialDescription(option, currency);
  const { currencyCode } = useCurrencyCodeStore();

  const { payment_type: type, price, frequency, trial_days, rebills } = option!;

  const renderDescription = () => {
    if (type === PaymentType.one_time_fee) {
      // Example: Billed one time for $19
      return `${t('billedOnceFor', {
        ns: 'paymentOptions',
      })} ${currencyText(currencyCode, currency, price)}`;
    }

    if (rebills && rebills > 0) {
      // Example: 3 Total Payments of $19/mo
      return `${rebills} ${t('totalPaymentsOf', {
        ns: 'prices',
      })} ${currencyText(currencyCode, currency, price)}${
        !!frequency ? t(`pricePeriod.${frequency}`, { ns: 'prices' }) : ''
      }`;
    }

    if (trial_days === 0) {
      // Examples:
      // Billed monthly for $19
      // Billed annually for $200
      // Billed weekly for $5
      // Billed daily for $1
      // Billed every 2 weeks for $10
      return `${t(`billed${getFrequencyKey(frequency!)}For`, {
        ns: 'paymentOptions',
      })} ${currencyText(currencyCode, currency, price)}`;
    }

    return trialDescription;
  };

  return <p className="text-xs mt-0.5 font-medium">{renderDescription()}</p>;
};

export default Description;

const frequencyKeys = {
  monthly: 'Monthly',
  annual: 'Annually',
  weekly: 'Weekly',
  daily: 'Daily',
  'every 2 weeks': 'Every2Weeks',
  'every 6 months': 'Every6Months',
};

const getFrequencyKey = (frequency: string) => {
  return frequencyKeys[frequency] || frequency;
};
