import { useTranslation } from 'next-i18next';
import {
  currencyText,
  formatPrice,
} from '../common/components/OrderSummary/utils';
import { components } from '../schemas/offer';
import useCurrencyCodeStore from '../common/zustand/useCurrencyCodeStore';

const useTrialDescription = (
  option: components['schemas']['PaymentOption'] | null,
  currency: components['schemas']['Currency']['symbol']
): string => {
  const { t } = useTranslation('prices');
  const { currencyCode } = useCurrencyCodeStore();

  if (!option) {
    return '';
  }

  const trialPrice = formatPrice(Number(option.trial_period_price ?? 0));

  const isPaidTrial =
    option.trial_period_price &&
    +option.trial_period_price > 0 &&
    Number(option.trial_days ?? 0) > 0;

  const trialDate =
    option.trial_days && +option.trial_days % 7 === 0
      ? +option.trial_days / 7 + ` ${t ? t('week', { ns: 'prices' }) : 'Week'}`
      : option.trial_days + `-${t ? t('day', { ns: 'prices' }) : 'Day'}`;

  // Examples:
  // 1-Week Free Trial
  // 10-Day Trial for $5
  return (
    trialDate +
    (!isPaidTrial
      ? ` ${t ? t('freeTrial', { ns: 'prices' }) : 'Free Trial'}`
      : ` ${t ? t('trialFor', { ns: 'prices' }) : 'Trial for'} ${currencyText(
          currencyCode,
          currency,
          trialPrice
        )} `)
  );
};

export default useTrialDescription;
