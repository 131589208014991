import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { currencyText, formatPrice, isZeroDecimalCurrency } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { components } from '@/src/schemas/offer';
import { useTodayTotal } from '@/src/hooks';
import { usePageStore } from '../../zustand';
import { DiscountCode } from './DiscountCode';
import classNames from 'classnames';
import { CouponState } from '@/src/modules/payment-page/models/elements/coupon';
import useCurrencyCodeStore from '../../zustand/useCurrencyCodeStore';

type ITodayTotalProps = {
  option: components['schemas']['PaymentOption'];
  currency: components['schemas']['Currency']['symbol'];
  bumpSelected?: boolean;
  isMobileDrawer?: boolean;
};

const TodayTotalSub: FC<ITodayTotalProps> = ({
  currency,
  isMobileDrawer,
  option,
}): JSX.Element => {
  const { t } = useTranslation(['paymentPage', 'prices']);

  const { discount, taxes, subtotal, total } = useTodayTotal();

  const zeroDecimalCurrency = isZeroDecimalCurrency(currency);
  const forceTwoDecimals = !zeroDecimalCurrency;
  const totalToDisplay = zeroDecimalCurrency ? total : total / 100;

  const discountCode = usePageStore((state) => state.discountCode);

  const couponState = usePageStore((state) => state.couponState);

  const discountData = usePageStore((state) => state.discount);
  const discountRecurring = discountData?.recurring ?? false;

  const { currencyCode } = useCurrencyCodeStore();

  if (isMobileDrawer) {
    return (
      <span className="flex-1 self-center text-right font-semibold">
        {currencyText(
          currencyCode,
          currency,
          formatPrice(totalToDisplay, forceTwoDecimals)
        )}
        {taxes > 0 && (
          <span className="text-xs italic font-thin"> {t('plus tax')}</span>
        )}
      </span>
    );
  }

  return (
    <div>
      {discount > 0 && !discountCode && (
        <div className={`flex`}>
          <span>{`${t('discount')}:`}</span>
          <div className="flex-1 text-right">
            -
            {currencyText(
              currencyCode,
              currency,
              formatPrice(discount, forceTwoDecimals)
            )}
          </div>
        </div>
      )}
      {subtotal > 0 && taxes > 0 && (
        <div className={`flex`}>
          <span>{`${t('subTotal')}:`}</span>
          <div className="flex-1 text-right">
            {currencyText(
              currencyCode,
              currency,
              formatPrice(subtotal, forceTwoDecimals)
            )}
          </div>
        </div>
      )}
      {taxes > 0 && (
        <div className={`flex mt-3`}>
          <span>{`${t('Estimated Tax')}: `}</span>
          <div className="flex-1 text-right">
            {currencyText(
              currencyCode,
              currency,
              formatPrice(taxes, forceTwoDecimals)
            )}
            <OverlayTrigger
              trigger={['hover']}
              overlay={
                <Tooltip
                  className="relative border w-1/2 lg:w-1/4 rounded-sm p-4 bg-white"
                  id={'estimatedTax'}
                >
                  {t('taxDescription')}
                </Tooltip>
              }
              placement="bottom"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-base pl-2" />
            </OverlayTrigger>
          </div>
        </div>
      )}
      {!!discountCode &&
        !!(discountRecurring || option.rebills) &&
        couponState !== CouponState.inapplicable && (
          <DiscountCode variation={2} />
        )}
      <div className={classNames(`flex font-bold mt-3 text-2xl`)}>
        <span>{`${t('todayTotal')}:`}</span>
        <div className="flex-1 text-right">
          {currencyText(
            currencyCode,
            currency,
            formatPrice(totalToDisplay, forceTwoDecimals)
          )}
          {taxes > 0 && totalToDisplay > 0 && (
            <span className="text-md italic font-normal"> {t('plus tax')}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodayTotalSub;
