import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { IOtpPrices, TaxCalcOTP } from '../../zustand/types';
import { currencyText, formatPrice } from './utils';
import classNames from 'classnames';
import useCurrencyCodeStore from '../../zustand/useCurrencyCodeStore';

type ITodayTotalProps = {
  taxCalc: TaxCalcOTP;
  prices: IOtpPrices | null;
  isMobileDrawer?: boolean;
};

const TodayTotalOTP: FC<ITodayTotalProps> = ({
  taxCalc,
  prices,
  isMobileDrawer,
}): JSX.Element => {
  const { t } = useTranslation(['paymentPage', 'prices']);
  const { currencyCode } = useCurrencyCodeStore();

  const { main_tax, bump_tax } = taxCalc;

  if (!prices) {
    return <></>;
  }

  const {
    mainPrice,
    bumpPrice,
    discountPrice,
    bumpSelected,
    isZeroDecimalCurrency,
    currencySymbol,
  } = prices;

  const subtotal =
    mainPrice + (bumpSelected && bumpPrice ? bumpPrice : 0) - discountPrice;
  let taxes = (main_tax ?? 0) + (bumpSelected && bump_tax ? bump_tax : 0);

  if (taxes > 0 && !isZeroDecimalCurrency) {
    taxes = taxes / 100;
  }

  const total = subtotal + taxes;
  const forceTwoDecimals = !isZeroDecimalCurrency;

  if (isMobileDrawer) {
    return (
      <span className="flex-1 self-center text-right font-semibold">
        {currencyText(
          currencyCode,
          currencySymbol,
          total > 0 ? formatPrice(+total, forceTwoDecimals) : '0.00'
        )}
      </span>
    );
  }

  return (
    <div>
      <div
        className={classNames(
          { 'border-b-2 pb-4': discountPrice > 0 },
          'md:text-lg pt-4'
        )}
      >
        {discountPrice > 0 && (
          <div className={`flex mb-2`}>
            <span>{`${t('subTotal')}:`}</span>
            <div className="flex-1 text-right">
              {currencyText(
                currencyCode,
                currencySymbol,
                formatPrice(+subtotal + discountPrice, forceTwoDecimals)
              )}
            </div>
          </div>
        )}
        {discountPrice > 0 && (
          <div className={`flex`}>
            <span>{`${t('discount')}:`}</span>
            <div className="flex-1 text-right">
              -
              {currencyText(
                currencyCode,
                currencySymbol,
                formatPrice(+discountPrice, forceTwoDecimals)
              )}
            </div>
          </div>
        )}
        {subtotal > 0 && taxes > 0 && (
          <div className={`flex`}>
            <span>{`${t('subTotal')}:`}</span>
            <div className="flex-1 text-right">
              {currencyText(
                currencyCode,
                currencySymbol,
                formatPrice(+subtotal, forceTwoDecimals)
              )}
            </div>
          </div>
        )}
        {taxes > 0 && (
          <div className={`flex`}>
            <span>{`${t('Tax')}:`}</span>
            <div className="flex-1 text-right">
              {currencyText(
                currencyCode,
                currencySymbol,
                formatPrice(+taxes, forceTwoDecimals)
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames(
          { 'pt-2': discountPrice > 0 },
          'flex font-bold text-[1.375rem] md:text-2xl'
        )}
      >
        <span>{`${t('todayTotal')}:`}</span>
        <div className="flex-1 text-right">
          {currencyText(
            currencyCode,
            currencySymbol,
            total > 0 ? formatPrice(+total, forceTwoDecimals) : '0.00'
          )}
        </div>
      </div>
    </div>
  );
};

export default TodayTotalOTP;
